import NotFound from "@components/NotFound/NotFound.vue";
import DashboardRoutes from "@features/dashboard/DashboardRoutes.ts";
import WorkflowsRoutes from "@features/workflows/WorkflowsRoutes.ts";
import type { RouteRecordRaw } from "vue-router";
import SalesRoutes from "@features/sale/SalesRoutes.ts";

export const MainRoutes: RouteRecordRaw[] = [
  {
    path: "/dashboard",
    children: DashboardRoutes,
    meta: {
      navbar: {
        name: "dashboard",
        icon: "pi-chart-bar",
      },
    },
  },
  {
    path: "/sales",
    children: SalesRoutes,
    meta: {
      navbar: {
        name: "sales",
        icon: "pi-money-bill",
      },
    },
  },
  {
    path: "/workflows",
    children: WorkflowsRoutes,
    meta: {
      navbar: {
        name: "workflows",
        icon:
            "pi-arrow-right-arrow-left",
      },
    },
  },
  {
    path: "/:notFound",
    component: NotFound,
  },
];

export default MainRoutes;
