import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@src/theme.css";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import app from "@core/configApp.ts";
import Toast from "primevue/toast";
import BadgeDirective from "primevue/badgedirective";
import primeNL from "@core/plugins/prime-nl.json";
import ConfirmDialog from "primevue/confirmdialog";

app.use(ConfirmationService);
app.use(ToastService);
app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);
app.directive("badge", BadgeDirective);
app.use(PrimeVue, { ripple: true, locale: primeNL });
app.component("Toast", Toast);
app.component("ConfirmDialog", ConfirmDialog);
