interface FailedQueueItem {
  resolve: () => void
  reject: (error: any) => void
}

interface State {
  failedQueue: FailedQueueItem[]
}
export function useRefreshTokenInterceptor() {
  let isRefreshing = false;
  const { token } = useAuthStore();

  const state: State = {
    failedQueue: [],
  };

  async function startRefresh() {
    if (isRefreshing) {
      return new Promise<void>((resolve, reject) => {
        state.failedQueue.push({ resolve, reject });
      });
    }

    function refreshSuccess() {
      processQueue();
      isRefreshing = false;
    }

    function refreshFailed(error: any) {
      processQueue(error);
      isRefreshing = false;
      throw error;
    }

    isRefreshing = true;

    try {
      await setRefreshToken();
    } catch (error) {
      return refreshFailed(error);
    }
    return refreshSuccess();
  }

  async function interceptor(failedRequest: any) {
    const originalRequest = failedRequest.config;

    if (AUTH_ROUTES.includes(originalRequest.url)) {
      throw failedRequest;
    }

    if (failedRequest.response.status !== 401) {
      throw failedRequest;
    }

    if (originalRequest.retry) {
      throw failedRequest;
    }
    originalRequest.retry = true;
    return startRefresh().then(() => {
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return api.request(originalRequest);
    });
  }

  function processQueue(error = null) {
    state.failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      }
      promise.resolve();
    });
  }

  return {
    interceptor,
  };
}
