<script setup lang="ts">
import type { ChartOptions } from "chart.js";
import Chart from "primevue/chart";
import { onMounted } from "vue";
import dayjs from "dayjs";
import type { SaleGroupedByItem, SaleStatistic } from "@features/sale/sale.interface.ts";

const { t, n } = useI18n();
const recurringMonthly = ref(0);
const payproTotalAmount = ref(0);
const totalOutstanding = ref(0);
const totalOutstandingWithoutWorkflow = ref(0);

/* Here is real data from teh API call */
const salesData = ref<SaleGroupedByItem[]>([]);
const currentPage = ref(0);
const totalPages = ref(0);
const perPage = 10;

const paginatedSales = computed(() => {
  return [...salesData.value].splice((currentPage.value - 1) * perPage, perPage);
});

async function fetchChartData() {
  const { data } = await api.get<SaleStatistic>("/api/statistics");
  recurringMonthly.value = data.recurringMonthly / 100;
  payproTotalAmount.value = data.payproTotalAmount / 100;
  totalOutstanding.value = data.totalOutstanding / 100;
  totalOutstandingWithoutWorkflow.value = data.totalOutstandingWithoutWorkflow / 100;
  salesData.value = data.salesGroupedByCreatedAt.reverse();

  currentPage.value = Math.round(data.salesGroupedByCreatedAt.length / perPage);
  totalPages.value = Math.round(data.salesGroupedByCreatedAt.length / perPage);
}

onMounted(() => {
  fetchChartData();
});

const chartData = computed(() => {
  return {
    labels: paginatedSales.value.map(sale => dayjs(sale.createdAtDate).format("DD MMMM YYYY")),
    datasets: [
      {
        label: "Verkopen",
        data: paginatedSales.value.map(sale => sale.saleCount),
        backgroundColor: "#46b4e8",
      },
    ],
  };
});

function prevPage() {
  if (currentPage.value === 1) {
    return;
  }
  currentPage.value = currentPage.value - 1;
}

function nextPage() {
  console.log(currentPage.value);
  console.log(totalPages.value);
  if (currentPage.value > totalPages.value - 1) {
    return;
  }
  currentPage.value = currentPage.value + 1;
}

const chartOptions = ref<ChartOptions>({
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  animation: {
    duration: 100,
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1 / 2,
});
</script>

<template>
  <div class="font-medium text-3xl text-900 ">
    Dashboard
  </div>
  <div class="grid surface-ground my-1 border-round ">
    <div class="col-12 md:col-6 lg:col-4">
      <div class=" py-3 surface-card border-round flex">
        <div class="icon-wrapper px-3">
          <i class="pi pi-calendar text-4xl text-cyan-600 bg-cyan-100 p-4 border-round-2xl" />
        </div>
        <div class="text-wrapper flex flex-column justify-content-center">
          <div class="text-900 font-bold text-xl">
            {{ recurringMonthly ? n(recurringMonthly, 'currency') : '0' }}
          </div>
          <p class="text-700 line-height-3 m-0">
            {{ t('common.dash-board.recurring-monthly') }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
      <div class=" py-3 surface-card border-round flex">
        <div class="icon-wrapper px-3">
          <i class="pi pi pi-wallet text-4xl text-blue-600 bg-blue-100 p-4 border-round-2xl" />
        </div>
        <div class="text-wrapper flex flex-column justify-content-center">
          <div class="text-900 font-bold text-xl">
            {{ totalOutstanding ? n(totalOutstanding, 'currency') : '0' }}
          </div>
          <p class="text-700 line-height-3 m-0">
            {{ t('common.dash-board.total-outstanding') }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
      <div class=" py-3 surface-card border-round flex">
        <div class="icon-wrapper px-3">
          <i class="pi pi pi-wallet text-4xl text-blue-600 bg-blue-100 p-4 border-round-2xl" />
        </div>
        <div class="text-wrapper flex flex-column justify-content-center">
          <div class="text-900 font-bold text-xl">
            {{ totalOutstandingWithoutWorkflow ? n(totalOutstandingWithoutWorkflow, 'currency') : '0' }}
          </div>
          <p class="text-700 line-height-3 m-0">
            {{ t('common.dash-board.total-outstanding-without-workflow') }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
      <div class=" py-3 surface-card border-round flex">
        <div class="icon-wrapper px-3">
          <i class="pi pi-euro text-4xl text-indigo-600 bg-indigo-100 p-4 border-round-2xl" />
        </div>
        <div class="text-wrapper flex flex-column justify-content-center">
          <div class="text-900 font-bold text-xl">
            {{ payproTotalAmount ? n(payproTotalAmount, 'currency') : '0' }}
          </div>
          <p class="text-700 line-height-3 m-0">
            {{ t('common.dash-board.total-amount') }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class=" surface-card p-3 border-round">
    <h3>{{ t('common.dash-board.total-sales-grouped-by', { count: salesData.length }) }}</h3>
    <Chart type="bar" :data="chartData" :options="chartOptions" :canvas-props="{ height: '450' }" />
    <div class="pt-3 flex flex-row justify-content-around">
      <span class="p-buttonset">
        <Button icon="pi pi-arrow-left" severity="success" :disabled="currentPage === 1" @click="prevPage" />
        <Button icon="pi pi-arrow-right" severity="success" :disabled="currentPage === totalPages" @click="nextPage" />
      </span>
    </div>
    <div class="text-500 pb-3 pt-2 text-center">
      Pagina {{ currentPage }} van {{ totalPages }}
    </div>
  </div>
</template>

<style lang="scss">
.z-999 {
  z-index: 999;
}
</style>
