import app from "./core/configApp";
import "./style.css";
import "@core/plugins/plugin-pinia.ts";
import "@core/plugins/plugin-prime.ts";
import "@core/plugins/plugin-i18n.ts";
import "@core/plugins/plugin-infiniteloading.ts";
import "@core/plugins/plugin-yup.ts";
import "@core/plugins/plugin-dayjs.ts";
import "@src/routes/plugin-router.ts";

if (import.meta.env.DEV) {
  if (window.location.hostname === "localhost") {
    window.location.hostname = "rankingpartner.localhost";
  }
}
async function initializeApp() {
  // if (["development"].includes(import.meta.env.MODE)) {
  //   console.log("mocking");
  //   const { worker } = await import("../mocks/browser");
  //   await worker.start({ onUnhandledRequest: "bypass" });
  //   if (window.Cypress) {
  //     window.appReady = true;
  //   }
  // }
  app.mount("#app");
}

initializeApp().then();
