import type { I18nOptions } from "vue-i18n";
import { createI18n } from "vue-i18n";
import app from "@core/configApp";
import common from "@translations/nl/common.json";

const options: I18nOptions = {
  legacy: false,
  locale: "nl",
  messages: {
    nl: {
      common,
    },
  },
  numberFormats: {
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
};
export const i18n = createI18n<false, typeof options>(options);
// export type MessageSchema = typeof nlNL
//
// const i18n = createI18n<[MessageSchema], 'nl-NL'>({
//   legacy: false,
//   locale: 'nl-NL',
//   messages: {
//     'nl-NL': nlNL,
//   },
//   numberFormats: {
//     'nl-NL': {
//       currency: {
//         style: 'currency',
//         currency: 'EUR',
//       },
//     },
//   },
// })

app.use(i18n);
