import type { RouteRecordRaw } from "vue-router";

export const WorkflowsRoutes: RouteRecordRaw[] = [
  {
    path: "/workflows", // Update the parent route's path
    name: "workflows",
    component: () => import("@features/workflows/Workflows.vue"),
  },
];

export default WorkflowsRoutes;
