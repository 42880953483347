{
  "workflow_status": {
    "options": {
      "pending": "Nieuwe starten",
      "running": "Lopend",
      "stopped": "Gestopt",
      "paused": "Gepauzeerd",
      "finished": "Voltooid",
      "resume": "Hervat"
    }
  },
  "actions": "Acties",
  "cancel": "Annuleren",
  "forms": {
    "email": "Vul een geldige email in",
    "invalid": "Ongeldig ingevuld",
    "max": "Vul maximaal {0} karakters in",
    "min": "Vul minimaal {0} karakters in",
    "required": "Dit veld is verplicht"
  },
  "search": "Zoeken",
  "logout": {
    "message": {
      "success": {
        "description": "Je bent succesvol uitgelogd",
        "title": "Success!"
      }
    }
  },
  "status": {
    "running": "Lopend",
    "stopped": "Gestopt",
    "pending": "In afwachting",
    "open": "Open",
    "lost": "Verloren",
    "won": "Gewonnen",
    "deleted": "Verwijderd",
    "all_not_deleted": "Alle niet verwijderde"
  },
  "data": {
    "status": "Status",
    "lost": "Verloren",
    "open": "Open"
  },
  "navbar": {
    "inbox": {
      "title": "Inbox"
    },
    "notifications": {
      "title": "Notificaties"
    }
  },
  "login": {
    "button": "Inloggen op portaal",
    "form": {
      "email": "Email",
      "email_placeholder": "Email address",
      "forgot_password": "Wachtwoord vergeten?",
      "password": "Wachtwoord",
      "password_placeholder": "Wachtwoord",
      "rememberme": "Onthoud mij",
      "subtitle": "Log in met jouw email en wachtwoord.",
      "sub": "Welkom op het Rankingpartner Portaal"
    },
    "message": {
      "success": {
        "description": "Je bent succesvol ingelogd",
        "title": "Success!"
      }
    }
  },
  "search_on_amount": "Zoek op bedrag",
  "password_reset": {
    "button": "Reset aanvragen",
    "form": {
      "email": "Email",
      "email_placeholder": "Email address",
      "sub": "Voer het e-mailadres in dat aan uw account is gekoppeld en we sturen u een link om uw wachtwoord opnieuw in te stellen."
    },
    "back_to_login": "Terug naar inloggen",
    "message": {
      "success": {
        "description": "Je bent succesvol ingelogd",
        "title": "Success!"
      }
    }
  },
  "settings": {
    "form": {
      "email": "Email",
      "email_placeholder": "Email address"
    }
  },
  "payment_status": {
    "1": "Betalen",
    "2": "Aangemeld",
    "3": "Geannuleerd",
    "4": "Betaald"
  },
  "server": {
    "400": "(400) Ongeldige data verzonden. Probeer opnieuw.",
    "401": "(401) Ongeldige gegevens.",
    "402": "(402) Uw abonnement of demo is verlopen. Neem contact op met support.",
    "403": "(403) Onvoldoende rechten.",
    "404": "(404) Niet gevonden.",
    "405": "(405) Deze verzoeksmethode is niet toegestaan.",
    "406": "(406) Verzoek niet geaccepteerd",
    "415": "(415) Dit formaat wordt niet ondersteund.",
    "422": "(422) Ongeldige data verzonden. Probeer opnieuw.",
    "426": "(426) Uw apparaat heeft onvoldoende beveiliging, probeer uw apparaat te updaten of neem contact op met de beheerder.",
    "429": "(429) Probeer het over enkele minuten opnieuw.",
    "500": "(500) Er ging iets fout aan onze kant, neem contact op met de support of kijk op https://status.rankingpartner.nl",
    "502": "(502) Server onbereikbaar, neem contact op met de support of kijk op https://status.rankingpartner.nl",
    "503": "(503) Server onbereikbaar, neem contact op met de support of kijk op https://status.rankingpartner.nl",
    "505": "(505) Versie niet ondersteund, neem contact op met de support.",
    "511": "(511) Netwerk authenticatie vereist.",
    "unknown_error": "({errorCode}) Er is een onbekende fout opgetreden."
  },
  "routes": {
    "sales-sale_payments-sale_payment_sequences": "Verkoop betalingen reeksen",
    "sales-sale_payments-sale_payment_sequences-workflows": "Workflow Statusses",
    "sales-sale_payments": "Verkoop betalingen",
    "dashboard": "Dashboard",
    "sales": "Sales",
    "workflows": "Workflows"
  },
  "none_found": "Geen resultaten gevonden",
  "clear_filters": "Filters wissen",
  "dash-board": {
    "recurring-monthly": "Terugkerende betalingen",
    "total-outstanding": "Totaal openstaand",
    "total-outstanding-without-workflow": "Totaal openstaand zonder workflow",
    "total-amount": "Totaal bedrag",
    "total-sales-grouped-by": "Laatste {count} verkopen"
  }
}
