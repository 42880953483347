import type { AppConfig } from "@composables/config/config.interface.ts";

export const useConfigStore = defineStore(
  "configStore",
  () => {
    const apiUrl = ref(import.meta.env.VITE_API_URL);
    const mercureUrl = ref(import.meta.env.VITE_MERCURE_URL);
    const cdnUrl = ref(import.meta.env.VITE_CDN_URL);
    const webUrl = ref(import.meta.env.VITE_WEB_URL);
    const appEnv = ref(import.meta.env.VITE_ENV_NAME);

    const reset = () => {
      apiUrl.value = import.meta.env.VITE_API_URL;
      mercureUrl.value = import.meta.env.VITE_MERCURE_URL;
      cdnUrl.value = import.meta.env.VITE_CDN_URL;
      webUrl.value = import.meta.env.VITE_WEB_URL;
      appEnv.value = import.meta.env.VITE_ENV_NAME;
    };
    const setConfig = (newConfig: AppConfig) => {
      apiUrl.value = newConfig.apiUrl;
      mercureUrl.value = newConfig.mercureUrl;
      cdnUrl.value = newConfig.cdnUrl;
      appEnv.value = newConfig.appEnv;
      if (newConfig.webUrl) {
        webUrl.value = newConfig.webUrl;
      }
    };

    return {
      apiUrl,
      mercureUrl,
      cdnUrl,
      webUrl,
      appEnv,
      setConfig,
      reset,
    };
  },
  {
    persist: [
      {
        paths: ["config"],
        storage: sessionStorage,
      },
    ],
  },
);
