import consola from "consola";

export const AUTH_ROUTES = [
  "/api/token/login",
  "/api/token/refresh",
  "/api/login",
  "/api/refresh",
];
const DEFAULT_ACCEPT = "application/ld+json";
const DEFAULT_CONTENT_TYPE = "application/ld+json";
const api = axios.create();
api.interceptors.request.use(async (config) => {
  const authStore = useAuthStore();
  const configStore = useConfigStore();
  if (!config?.url) {
    throw new Error("URL is undefined?");
  }

  config.baseURL = configStore.apiUrl;

  // Legacy
  if (!config.url.startsWith("/api")) {
    consola.warn(`Deprecated: Calling api.${config?.method?.toLowerCase()}() without a prefix like /api is deprecated.`);
    config.url = `/api${config.url}`;
  }
  if (config.method === "patch") {
    config.headers["Content-Type"] = "application/merge-patch+json";
  }
  if (!config.headers?.Accept) {
    config.headers.Accept = DEFAULT_ACCEPT;
  }

  if (!config.headers?.["Content-Type"]) {
    config.headers["Content-Type"] = DEFAULT_CONTENT_TYPE;
  }

  const authorizationHeaders = authStore.getAuthHeadersObject;
  if (!AUTH_ROUTES.includes(config.url) && authStore.hasToken) {
    Object.assign(config.headers, authorizationHeaders);
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  (failedRequest) => {
    const { interceptor } = useRefreshTokenInterceptor();
    return interceptor(failedRequest);
  },
);

export default api;
