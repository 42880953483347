import { setLocale } from "yup";
import { i18n } from "@core/plugins/plugin-i18n.ts";

setLocale({
  mixed: {
    default: i18n.global.t("common.forms.invalid"),
    required: i18n.global.t("common.forms.required"),
    // Add more default error messages for mixed type validations
  },
  string: {
    email: i18n.global.t("common.forms.email"),
    // Add more default error messages for string type validations
  },
  number: {
    min: min => i18n.global.t("common.forms.min", { min }),
    max: max => i18n.global.t("common.forms.max", { max }),
    // Add more default error messages for number type validations
  },
});
