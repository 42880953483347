<script lang="ts" setup>
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
      Pagina niet gevonden
    </div>
    <p class="text-700 text-3xl mt-0 mb-6 text-center">
      Sorry we konden deze pagina niet vinden
    </p>
    <div class="text-center">
      <Button class="p-button-text mr-2" label="Vorige pagina" icon="pi pi-arrow-left" @click="router.back()" />
      <Button label="Terug naar dashboard" icon="pi pi-home" @click="router.push('dashboard')" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
