import { routes } from "@src/routes/routes.ts";
import { createRouter, createWebHistory } from "vue-router";
import * as Sentry from "@sentry/vue";

import app from "@core/configApp.ts";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  const {
    isAuthenticated,
    isRemembered,
  } = useAuthStore();

  const isRequiredAuth = to.matched.some(record => record.meta.isRequiredAuth);

  if (isRequiredAuth && !isAuthenticated && !isRemembered) {
    console.log("redirecting...");
    const redirect = to.fullPath;
    return next({
      name: "login",
      params: { redirect },
    });
  } else {
    return next();
  }
});

app.use(router);
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://7e4b4e42dd5146e2a234927dac93fb7f@sentry.vitrion.nl//38",
    environment: window.location.hostname,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "app.testing.rankingpartner.nl",
          "app.staging.rankingpartner.nl",
          "app.rankingpartner.nl",
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}
