import type { LoginResponse } from "@features/auth/auth.interface.ts";

export async function setRefreshToken() {
  const authStore = useAuthStore();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log(authStore.getRefreshToken);

  let response;
  try {
    response = await api.post<LoginResponse>(
      "/api/refresh",
      { refresh_token: authStore.getRefreshToken },
      config,
    );
  } catch (refreshTokenException) {
    return Promise.reject(new ExpiredRefreshTokenError());
  }

  const { data } = response;
  if (!data) {
    return Promise.reject(new Error("No data returned from the server"));
  }

  return useHandleAuthResponse(data);
}
