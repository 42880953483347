import type { RouteRecordRaw } from "vue-router";

const AuthRoutes: RouteRecordRaw[] = [
  {
    path: "login",
    name: "login",
    component: () => import("@features/auth/Login/Login.vue"),
  },
];
export default AuthRoutes;
