<script setup lang="ts">
if (["testing", "development"].includes(import.meta.env.MODE)) {
  onMounted(async () => {
    const { useLogin } = await import("@features/auth/Login/useLogin.ts");
    const { login } = useLogin();

    if (window.Cypress) {
      window.login = login;
    }
  });
}
// $primevue.config.ripple = true
</script>

<template>
  <Toast />
  <ConfirmDialog />

  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="scss">
.custom-switch {
  transform: scale(0.7);
}

.p-datatable-loading-overlay {
  background-color: rgba(255, 255, 255, 0.5)!important;
  z-index: 999;
}

//.row-hover {
//  &:hover >td  {
//    cursor:pointer;
//    background-color:var(--surface-100)!important;
//  }
//}
</style>
