import Dashboard from "@features/dashboard/Dashboard.vue";
import type { RouteRecordRaw } from "vue-router";

export const DashboardRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: "dashboard",
    component: Dashboard,
  },
];

export default DashboardRoutes;
