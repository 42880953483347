import type { RouteRecordRaw } from "vue-router";

export const SalesRoutes: RouteRecordRaw[] = [
  {
    path: "/sales", // Update the parent route's path
    name: "sales",
    component: () => import("@features/sale/Sales.vue"),
  },
  {
    path: "/sales/:id/sale_payments",
    name: "sales-sale_payments",
    component: () => import("@features/sale_payment/SalePayments.vue"),
  },
  {
    path: "/sales/:saleId/sale_payments/:salePaymentId/sale_payment_sequences",
    name: "sales-sale_payments-sale_payment_sequences",
    component: () => import("@features/sale_payment_sequence/SalePaymentSequences.vue"),
  },
  {
    path: "/sales/:saleId/sale_payments/:salePaymentId/sale_payment_sequences/:salePaymentSequenceId/workflows",
    name: "sales-sale_payments-sale_payment_sequences-workflows",
    component: () => import("@features/workflow_statuses/WorkflowStatuses.vue"),
  },

];

export default SalesRoutes;
