import type { Ref } from "vue";

export const useAuthStore = defineStore(
  "useAuthStore",
  () => {
    const token: Ref<string | null> = ref(null);
    const refreshToken: Ref<string | null> = ref(null);
    const refreshTokenRemembered: Ref<string | null> = ref(null);

    const isAuthenticated = computed(() => {
      return token.value !== null;
    });

    const isRemembered = computed(() => {
      return refreshTokenRemembered.value !== null;
    });

    const hasToken = computed(() => {
      return token.value !== null;
    });

    const getRefreshToken = computed(() => {
      return refreshToken.value
        ? refreshToken.value
        : refreshTokenRemembered.value;
    });

    const getAuthHeadersObject = computed(() => {
      const headers: Record<string, string | null> = {
        Authorization: `Bearer ${token.value}`,
      };

      return headers;
    });
    const tokenPayload = computed(() => {
      if (token.value === null) {
        return null;
      }

      const base64Url = token.value.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
          })
          .join(""),
      );

      return JSON.parse(jsonPayload);
    });

    function setToken(t: string) {
      token.value = t;
    }

    function reset() {
      token.value = null;
      refreshToken.value = null;
      refreshTokenRemembered.value = null;
    }

    function setRefreshToken(refresh: string, remembered: boolean) {
      refreshToken.value = refresh;

      if (remembered) {
        refreshTokenRemembered.value = refreshToken.value;
      }
    }

    return {
      reset,
      refreshTokenRemembered,
      hasToken,
      tokenPayload,
      setToken,
      getRefreshToken,
      getAuthHeadersObject,
      setRefreshToken,
      token,
      isRemembered,
      refreshToken,
      isAuthenticated,
    };
  },
  {
    persist: [
      {
        paths: ["token", "refreshToken"],
        storage: sessionStorage,
      },
      {
        paths: ["refreshTokenRemembered"],
        storage: localStorage,
      },
    ],
  },
);
