import AuthRoutes from "@features/auth/AuthRoutes.ts";
import MainRoutes from "@src/routes/MainRoutes.ts";

export const routes = [
  {
    name: "admin",
    path: "/",
    component: () =>
      import("@layouts/DefaultLayout.vue"),
    children: MainRoutes,
    meta: { isRequiredAuth: true },
  },
  {
    name: "Auth",
    path: "/auth",
    component: () => import("@features/auth/AuthContainer.vue"),
    children: AuthRoutes,
    meta: { isRequiredAuth: false },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "admin" },

  },
];
